<template>
  <div class="p-d-flex p-flex-column" style="width: 100%;" :style="`max-width: ${width}`">
    <div class="p-d-inline-flex p-flex-row p-justify-between">
      <div class="p-d-inline-flex p-justify-start" style="min-width: 30px; max-width: 40px">
        <Button v-if="showPause"
                :icon="playerMode === 'pause' ? 'mdi mdi-play-pause' : 'mdi mdi-play'" class="p-button-success p-mx-1"
                :disabled="playerMode === 'play'"
                @click.prevent="play"
        />
        <Button v-else
                :icon="playerMode === 'play' ? 'mdi mdi-stop' : 'mdi mdi-play'"
                class="p-button-success p-mx-1"
                @click.prevent="playerMode === 'play' ? stop() : play()"
        />
      </div>
      <div v-if="showPause" class="p-d-inline-flex p-justify-start" style="min-width: 30px; max-width: 40px">
        <Button icon="mdi mdi-pause" class="p-button-success p-mx-1"
                :disabled="!['play'].includes(playerMode)"
                @click.prevent="pause"
        />
      </div>
      <div v-if="showPause" class="p-d-inline-flex p-justify-start" style="min-width: 30px; max-width: 40px">
        <Button icon="mdi mdi-stop" class="p-button-success p-mx-1"
                :disabled="!['play', 'pause'].includes(playerMode)"
                @click.prevent="stop"
        />
      </div>
      <div class="p-d-inline-flex p-ai-center p-p-2" style="min-width: 140px; max-width: 1400px">
        <i :class="volumeIcon" class="p-button-success"
           style="color: #689F38; cursor: pointer"
           @click="volume > 0 ? volume = 0 : volume = 1"
        ></i>
        <Slider v-model="volume"
                :min="0" :max="1" :step="0.1"
                class="p-mx-2 p-button-success" style="min-width: 100px"/>
      </div>
      <!--      <div v-if="showVoiceSelector" class="p-d-inline-flex">-->
      <!--        <Button icon="mdi mdi-account-voice" class="p-button-success p-mx-1"-->
      <!--                aria-haspopup="true" aria-controls="overlay_menu"-->
      <!--                @click="toggleVoicesMenu"-->
      <!--        />-->
      <!--        <Menu id="overlay_menu" ref="voicesMenu" :model="voicesMenuItems" :popup="true" />-->
      <!--      </div>-->
    </div>
    <!--    <div class="p-d-inline-flex" style="width: 100%">-->
    <!--      <div class="p-d-inline-flex p-ai-center p-py-2 p-px-1" style="width: 100%">-->
    <!--        <ProgressBar :value="progress" :showValue="false" style="width: 100%; height: 5px" />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {textToSpeech} from "../api/alarmPoint";

export default {
  name: 'speech-text',
  props: {
    text: String,
    showPause: {
      type: Boolean,
      default: () => true,
    },
    showVoiceSelector: {
      type: Boolean,
      default: () => true,
    },
    width: {
      type: String,
      default: () => '350px'
    }
  },
  data: () => ({
    speech: null,
    playerMode: null,
    volume: 1,
    volumeIcons: {
      0: 'mdi mdi-volume-off',
      1: 'mdi mdi-volume-low',
      2: 'mdi mdi-volume-low',
      3: 'mdi mdi-volume-low',
      4: 'mdi mdi-volume-medium',
      5: 'mdi mdi-volume-medium',
      6: 'mdi mdi-volume-medium',
      7: 'mdi mdi-volume-medium',
      8: 'mdi mdi-volume-high',
      9: 'mdi mdi-volume-high',
      10: 'mdi mdi-volume-high',
    },
    availableVoices: [],
    voice: null,
    voiceName: '',
    progress: 0,
    linkToAudio: '',
    player: null
  }),
  mounted() {
    this.speech = new SpeechSynthesisUtterance(this.text)
    this.loadVoicesList()
    // fix for Firefox
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = this.loadVoicesList
    }
    this.initSpeech()
  },
  methods: {
    initSpeech() {
      if (this.text !== undefined && this.text != null && this.text.length > 1) {
        textToSpeech(this.text).then((response) => {
          this.linkToAudio = response.data
          this.player = new Audio(this.linkToAudio)
        })
      }
    },
    loadVoicesList() {
      this.availableVoices = window.speechSynthesis.getVoices()
      this.availableVoices.sort((a, b) => a.name.localeCompare(b.name))
      this.selectVoice(0)
    },
    listenerForSpeechEvents() {
      this.speech.onboundary = (e) => {
        const textLen = this.speech.text.length
        this.progress = Math.ceil(e.charIndex / textLen * 100)
      }
      this.speech.onstart = () => {
        this.playerMode = 'play'
        this.progress = 0
      }
      this.speech.onresume = () => {
        this.playerMode = 'play'
      }
      this.speech.onpause = () => {
        this.playerMode = 'pause'
      }
      this.speech.onend = () => {
        this.playerMode = null
        this.progress = 0
      }
      this.speech.onerror = e => {
        console.log(e);
      }
    },
    play() {
      this.player.play()
      this.playerMode = 'play'
    },
    pause() {
      this.player.pause()
      this.playerMode = 'pause'
    },
    stop() {
      this.player.pause();
      this.player.currentTime = 0;
      this.playerMode = 'stop'
    },
    toggleVoicesMenu(event) {
      this.$refs.voicesMenu.toggle(event);
    },
    selectVoice(i) {
      this.voice = this.availableVoices[i]
      if (this.voice) {
        this.voiceName = `${this.voice.name} (${this.voice.lang})`
        this.speech.voice = this.voice
      }
    }
  },
  computed: {
    volumeIcon: function () {
      return this.volumeIcons[this.volume * 10]
    },
    voicesMenuItems: function () {
      return this.availableVoices
          .map((v, i) => {
            return {
              label: `${v.name} (${v.lang})`,
              command: () => {
                this.selectVoice(i)
              }
            }
          })
    }
  },
  watch: {
    volume: function () {
      this.player.volume = this.volume
    },
    text() {
      this.initSpeech()
    },
    player() {
      console.log(this.player.currentTime)
      if (this.player.currentTime === 0) {
        this.player.pause()
      }
    }
  }
}
</script>

<style scoped>
::v-deep(.p-progressbar .p-progressbar-value),
::v-deep(.p-slider .p-slider-range),
::v-deep(.p-slider:not(.p-disabled) .p-slider-handle:hover) {
  background: #689F38;
}

::v-deep(.p-slider:not(.p-disabled) .p-slider-handle:hover),
::v-deep(.p-slider .p-slider-handle) {
  border: 2px solid #689F38;
}
</style>
