import request from "@/api/request";

export function manualCall(data) {
    return request({
        url: `/manual-call`,
        method: 'post',
        data: data
    })
}

export function getRecentText() {
    return request({
        url: `/manual-call/recent-text`,
        method: 'get'
    })
}

export function getTextTemplate() {
    return request({
        url: `/manual-call/text-template`,
        method: 'get'
    })
}
