import request from "@/api/request";

export function listInstructions(search = '', orgId = null) {
    if (orgId == null) {
        orgId = localStorage.getItem('orgId')
    }
    return request({
        url: `/list/instruction-files?orgdid=${orgId}`,
        method: 'get',
        query: {
            parentOrgId: orgId,
            filter: search
        }
    })
}

export function downloadInstruction(file) {
    request({
        url: `/list/instruction-files-content`,
        method: 'get',
        responseType: 'arraybuffer',
        params: {
            filename: file
        }
    }).then((response) => {
        const type = response.headers['content-type']
        const url = window.URL.createObjectURL(new Blob([response.data], {type: type, encoding: 'ISO-8859-1'}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file);
        document.body.appendChild(link)
        link.click()
    }).catch((error) => {
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage('Error reading instruction file' + (data.reason ? ': ' + data.reason : ''), 'error')
    })
}
