import request from "@/api/request";

export function create(text, userGroupIds) {
    return request({
        url: '/text-templates',
        method: 'POST',
        data: {
            text,
            userGroupIds
        }
    })
}

export function update(id, text, userGroupId) {
    return request({
        url: '/text-templates',
        method: 'PATCH',
        data: {
            id,
            text,
            userGroupId
        }
    })
}

export function getItemById(userGroupId) {
    return request({
        url: `/text-templates/${userGroupId}`,
        method: 'GET',
    })
}

export function removeItemById(id) {
    return request({
        url: `/text-templates/${id}`,
        method: 'DELETE',
    })
}
